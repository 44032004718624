/* You can add global styles to this file, and also import other style files */
@import "./assets/scss/app.scss";
// @import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=El+Messiri:wght@400;500;600;700&display=swap'); 
  @import url('https://fonts.googleapis.com/css2?family=Cairo:wght@300;400;600;700;900&display=swap');
@import url('https://fonts.google.com/noto/specimen/Noto+Sans+Hebrew?preview.layout=grid&query=heb');
:root {
  --main-font-family2: 'Noto Sans Hebrew', sans-serif;
}
body {
  background-color: #f5f8ff;
  // font-family: 'El Messiri';
  // font-family: 'Cairo';
  // font-family: 'Noto Sans Hebrew';
  font-family: var(--main-font-family2);

  color: black;
  font-weight: 700;
}
.router-wrapper  {
    position:  relative;
    overflow:  hidden;
    width:  100vw;
    height:  calc(100%  -  47px);
    perspective:  1200px;
    transform-style:  preserve-3d;
}

:host  {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background: #60d7a9;
}

td{
  font-size: 14px;
}

th{
  font-size: 14px;
}
.table i
{
    font-size: 16px;
}



@import "~ag-grid-community/styles/ag-grid.css";
@import "~ag-grid-community/styles/ag-theme-alpine.css";